import axios from 'axios';
import * as moment from 'moment';
import pkceChallenge from 'pkce-challenge';

import { Storage } from '@ionic/storage';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { isPlatform } from '@ionic/vue';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FRONTEND_BASE_URL, TRIQ_IDENTITY_PROVIDER_URL, config as APP_CONFIG } from '../config';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import router from '../router';

const store = new Storage();
store.create();

export default {
    namespaced: true,

    state: {
        google: {
            url: 'https://accounts.google.com/o/oauth2/v2/auth',

            client: {
                id: '755520977475-5d2o51hefj7s2njivjl8qh699gb4dl7a.apps.googleusercontent.com',
                secret: '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'
            },

            ios: {
                id: '630765435344-ks4ahennj5avo1lfh4bukjf7abke4q0j.apps.googleusercontent.com'
            },

            android: {
                id: '630765435344-k7if8ncioc7h8rbpqaneq697jet54rrj.apps.googleusercontent.com'
            }
        },

        triq: {
            url: TRIQ_IDENTITY_PROVIDER_URL,
            client: {
                id: 'triq-api-client-id',
                secret: 'a9403748-71e1-4e95-8237-31c3d1a8c67f' // TODO: remove this it is not needed anymore
            }
        },

        apple: {
            client: {
                id: 'com.sporttec.triq'
            }
        },

        refreshingToken: false
    },

    mutations: {
        async setAccessToken(state, accessToken) {
            if (accessToken) {
                await store.set('accessToken', accessToken);
            } else {
                await store.remove('accessToken');
            }
        },

        async setRecentProvider(state, provider) {
            await store.set(APP_CONFIG.ENVIRONMENT + '.recentProvider', provider);
        },

        setRefreshingToken(state, refreshingToken) {
            state.refreshingToken = refreshingToken;
        }
    },

    getters: {
        getGoogleAuthUrl(state) {
            const redirectUrl = FRONTEND_BASE_URL + '/oauth/google';

            return state.google.url +
                '?client_id=' + state.google.client.id +
                '&redirect_uri=' + encodeURIComponent(redirectUrl) +
                '&response_type=code' +
                '&scope=openid profile email' +
                '&prompt=consent' +
                '&access_type=offline';
        },

        getTriqAuthUrl(state) {
            const redirectUrl = FRONTEND_BASE_URL + '/signin-oidc';

            const pkce = pkceChallenge();

            sessionStorage.setItem('triq_oauth_code_verifier', pkce.code_verifier);

            const codeChallenge = pkce.code_challenge;

            return state.triq.url + '/connect/authorize' +
                '?client_id=' + state.triq.client.id +
                '&redirect_uri=' + encodeURIComponent(redirectUrl) +
                '&response_type=code' +
                '&scope=openid offline_access' +
                '&code_challenge=' + encodeURIComponent(codeChallenge) +
                '&code_challenge_method=S256';
        },

        getTriqLogoutUrl(state) {
            const cancelUrl = FRONTEND_BASE_URL + '/app/dashboard';
            const redirectUrl = FRONTEND_BASE_URL + '/login?logout';

            return state.triq.url + '/account/logout?cancelUrl=' + encodeURIComponent(cancelUrl) + '&returnUrl=' + encodeURIComponent(redirectUrl);
        }
    },

    actions: {
        init({ state }) {
            let clientId = state.google.client.id;

            if (isPlatform('ios') && (isPlatform('capacitor') || isPlatform('hybrid'))) {
                console.debug('Using Google for iOS');
                clientId = state.google.ios.id;
            } else if (isPlatform('android') && (isPlatform('capacitor') || isPlatform('hybrid'))) {
                console.debug('Using Google for Android');
                clientId = state.google.android.id;
            } else {
                console.debug('Using Google for Web');
            }

            GoogleAuth.initialize({
                clientId: clientId,
                scopes: ['openid', 'profile', 'email'],
                grantOfflineAccess: true,
            });
        },

        signInWithGoogle() {
            return GoogleAuth.signIn().then(response => {
                console.log('Google Auth Response', response);

                if (response.id && response.authentication) {
                    return {
                        id_token: response.authentication.idToken,
                        access_token: response.authentication.accessToken,
                        refresh_token: response.authentication.refreshToken
                    };
                }
            }).catch(err => {
                console.log('Google Auth Error', err);
            });
        },

        signInWithApple({ state }) {
            const options = {
                clientId: state.apple.client.id,
                redirectURI: FRONTEND_BASE_URL + '/oauth/apple',
                scopes: 'email name',
                state: '12345',
                nonce: 'nonce',
            };

            if (isPlatform('capacitor') || isPlatform('hybrid')) {
                options.clientId = state.apple.client.id;
            } else {
                options.clientId = state.apple.client.id + '.ionic';
            }

            return SignInWithApple.authorize(options).then(result => {
                console.log('Apple result', result);
                if (result.response && result.response.identityToken) {
                    return {
                        id_token: result.response.identityToken,
                        access_token: result.response.identityToken,
                        refresh_token: null // TODO: apple does not provide a refresh token
                    };
                }
            }).catch(error => {
                console.error('Apple error', error);
            });
        },

        // refreshAppleToken({ state, commit }, accessToken) {
        //     console.log('Refreshing Apple access token', accessToken);
        //     const url = 'https://appleid.apple.com/auth/token';

        //     const payload = {
        //         client_id: state.apple.client.id,
        //         client_secret: state.apple.client.secret,
        //         grant_type: 'refresh_token',
        //         refresh_token: accessToken.refresh_token
        //     };

        //     return axios.post(url, payload, {
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     }).then(response => {
        //         console.log('Received refresh response from Apple API', response.data);
        //         return commit('setAccessToken', response.data);
        //     });
        // },

        getAccessToken() {
            return store.get('accessToken');
        },

        getCurrentUser({ dispatch }) {
            return dispatch('getAccessToken').then(accessToken => {
                if (accessToken && accessToken.id_token) {
                    return JSON.parse(atob(accessToken.id_token.split('.')[1]));
                }

                console.debug('Failed to retrieve current user', accessToken);

                return null;
            });
        },

        getAccessTokenExpiration({ dispatch }) {
            return dispatch('getAccessToken').then(accessToken => {
                if (accessToken && accessToken.access_token) {
                    const payload = JSON.parse(atob(accessToken.access_token.split('.')[1]));
                    return payload.exp;
                }

                return null;
            });
        },

        isAccessTokenValid({ dispatch }) {
            return dispatch('getAccessTokenExpiration').then(accessTokenExpiration => {
                if (accessTokenExpiration) {
                    const isValid = accessTokenExpiration >= moment.utc().unix();
                    if (!isValid) {
                        console.debug('Access token is expired');
                    }

                    return isValid;
                }

                console.debug('Access token is not valid anymore')

                return false;
            }).catch(err => {
                console.error('Error while checking access token', err);
                return false;
            });
        },

        async isLoggedIn({ dispatch }) {
          return await dispatch('isAccessTokenValid');
        },

        getGoogleToken({ state }, code) {
            const url = 'https://oauth2.googleapis.com/token';

            const payload = {
                code: code,
                client_id: state.google.client.id,
                client_secret: state.google.client.secret,
                redirect_uri: FRONTEND_BASE_URL + '/oauth/google',
                grant_type: 'authorization_code',
                approval_prompt: 'force',
                access_type: 'offline'
            };

            return axios.post(url, payload).then(response => {
                console.log('Received response from Google', response.data);

                return response.data;
				// commit('setAccessToken', response.data);
            })
        },

		getTriqTokenWithExternal({ state, commit }, { accessToken, provider }) {
            const url = state.triq.url + '/connect/token';

            // TODO: this has also to be done with apple

            console.log('Exchange ' + provider + ' token for TRIQ token', accessToken);
            const payload = {
                external_id_token: accessToken.id_token,
                external_access_token: accessToken.access_token,
                provider: provider,
                client_id: state.triq.client.id,
                grant_type: 'external',
            };
            console.log('payload', payload);

            return axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                console.log('Received response from Identity Provider', response.data);
                
                commit('setAccessToken', response.data);
                commit('setRecentProvider', provider);
            });
		},

        // refreshGoogleToken({ state, commit }, accessToken) {
        //     console.log('Refreshing Google access token', accessToken);
        //     const url = 'https://oauth2.googleapis.com/token';

        //     const payload = {
        //         client_id: state.google.client.id,
        //         client_secret: state.google.client.secret,
        //         grant_type: 'refresh_token',
        //         refresh_token: accessToken.refresh_token,
        //         access_type: 'offline'
        //     };

        //     return axios.post(url, payload, {
        //         headers: {
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     }).then(response => {
        //         console.log('Received refresh response from Google API', response.data);

        //         const newToken = response.data;
        //         newToken.refresh_token = accessToken.refresh_token; // keep refresh token

        //         return commit('setAccessToken', newToken);
        //     });
        // },

        getTriqToken({ state, commit }, code) {
            const url = state.triq.url + '/connect/token';

            const payload = {
                code: code,
                client_id: state.triq.client.id,
                // client_secret: state.triq.client.secret,
                redirect_uri: FRONTEND_BASE_URL + '/signin-oidc',
                grant_type: 'authorization_code',
                code_verifier: encodeURIComponent(sessionStorage.getItem('triq_oauth_code_verifier'))
            };

            return axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                console.log('Received response from TRIQ IP', response.data);
                commit('setAccessToken', {
                    id_token: response.data.id_token,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                });
                commit('setRecentProvider', 'triq');
            });
        },

        refreshTriqToken({ state, commit, dispatch }, accessToken) {
            if (state.refreshingToken) {
                // wait until refreshtinToken turns to false
                console.log('Already refreshing token - waiting until token has been refreshed');
                return new Promise((resolve, reject) => {
                    let executionCount = 0;
                    const interval = setInterval(async () => {
                        if (!state.refreshingToken) {
                            clearInterval(interval);
                            resolve(await dispatch('getAccessToken'));
                        }
                        executionCount++;

                        if (executionCount > 10) {
                            clearInterval(interval);
                            console.error('Timeout - while waiting for token to be refreshed');
                            reject('Timeout - while waiting for token to be refreshed');
                        }
                    }, 1000);
                });
            }

            commit('setRefreshingToken', true);

            console.log('Refreshing TRIQ access token', accessToken);
            const url = state.triq.url + '/connect/token';

            const payload = {
                client_id: state.triq.client.id,
                // client_secret: state.triq.client.secret,
                grant_type: 'refresh_token',
                refresh_token: accessToken.refresh_token
            };

            return axios.post(url, payload, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                console.log('Received refresh response from TRIQ IP', response.data);
                commit('setRefreshingToken', false);

                const newAccessToken = {
                    id_token: response.data.id_token,
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token
                };

                commit('setAccessToken', newAccessToken);

                return newAccessToken;
            }).catch(err => {
                console.error('Error refreshing token', err);
                commit('setRefreshingToken', false);

                // if refreshing token fails -> logout
                commit('setAccessToken', null);
                router.replace('/login');
            });
        },

        refreshAccessToken({ dispatch }) {
            
            return dispatch('getAccessToken').then(async accessToken => {
                if (accessToken) {
                    // return dispatch('getCurrentUser').then(currentUser => {
                        // console.debug('Trying to refresh access token', currentUser);
                        // if (currentUser.aud == state.triq.client.id) { // TRIQ
                            return await dispatch('refreshTriqToken', accessToken);
                        // } else if (currentUser.iss == 'https://accounts.google.com') { // Google
                        //     return dispatch('refreshGoogleToken', accessToken);
                        // } else if (currentUser.iss == 'https://appleid.apple.com') { // Apple
                            // TODO: return dispatch('refreshAppleToken', accessToken);
                        // }
                    // });
                }
            });
        },

        preAuth({ dispatch }) {
            return dispatch('api/fetchIdentity', null, {root: true}).then(response => {
                dispatch('getAccessToken').then(accessToken => {
                    console.debug('Access Token', accessToken);
                }).catch(err => {
                    console.error('Failed to get access token - for debugging', err);
                });

                return response;
            });
        },

        async logout({ state, commit, dispatch, getters }, force = false) {
            try {
                const currentUser = await dispatch('getCurrentUser');
                console.debug('Logging out', currentUser);

                if (currentUser) {
                    // deregister push notifications if enabled
                    const deviceId = await store.get('deviceId');
                    console.log('Logging out Device ID:', deviceId);
                    if (deviceId) {
                        dispatch('api/unregisterDevice', deviceId, {root: true}).catch(err => {
                            console.error('Failed to unregister device', err);
                        });
                    }
                }

                if (force) {
                    commit('setAccessToken', null);
                    router.replace('/login');
                    return;
                }

                if (currentUser.aud == state.triq.client.id) { // TRIQ
                    const logoutUrl = getters['getTriqLogoutUrl'];
                    if (isPlatform('capacitor') || isPlatform('hybrid')) {
                        const browser = InAppBrowser.create(logoutUrl, '_blank', 'location=no');
                        browser.on('loadstart').subscribe(event => {
                            if (event.url) {
                                console.debug('TRIQ Event URL', event.url);
                                if (event.url.match(FRONTEND_BASE_URL)) {
                                    browser.close();

                                    if (event.url.match(/\?logout/)) {
                                        commit('setAccessToken', null);
                                        router.replace('/login');
                                    }
                                }
                            }
                        });
                    } else {
                        commit('setAccessToken', null);
                        location.href = logoutUrl;
                    }

                    return;
                }
            } catch (err) {
                console.error('Logout failed', err);
                // TODO: commit('setAccessToken', null);
            }

            setTimeout(() => {
                // always logout anyway
                console.debug('Logging out now anyway');
                commit('setAccessToken', null);

                console.debug('Redirecting to home');
                router.replace('/login');
            }, 500);
        },

        request({ dispatch }, callback) {
            let triqAction = null;  
            if (typeof callback !== 'function' && typeof callback == 'object') {
                if (callback.action) {
                    triqAction = callback.action;
                }

                callback = callback.callback;
            }

            return dispatch('getAccessToken').then(async accessToken => {
                if (!await dispatch('isAccessTokenValid')) {
                    console.debug('Access token expired - refreshing');
                    accessToken = await dispatch('refreshAccessToken');
                    console.debug('Access token refreshed', accessToken);
                }

                const config = {
                    headers: {
                        "Authorization": null,
                        "Content-Type": "application/json",

                        // additional headers
                        "X-TRIQ-App-Version": APP_CONFIG.SENTRY_VERSION,
                        "X-TRIQ-App-Environment": APP_CONFIG.ENVIRONMENT,
                        "X-TRIQ-App-API-Version": APP_CONFIG.API_VERSION,

                        "X-TRIQ-App-Page": router.currentRoute.value.path
                    }
                };

                if (accessToken && accessToken.access_token) {
                    config.headers.Authorization = "Bearer " + accessToken.access_token;
                }

                if (triqAction)  {
                    config.headers["X-TRIQ-App-Action"] = triqAction;
                }

                return callback(config);
            });
        },

        async getRecentProvider({ commit }) {
            return await store.get(APP_CONFIG.ENVIRONMENT + '.recentProvider');
        }
    }
};