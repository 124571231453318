import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import { Device } from '@awesome-cordova-plugins/device';
import { isPlatform, modalController } from '@ionic/vue';
import { Storage } from '@ionic/storage';
import { Capacitor } from '@capacitor/core';
import router from '@/router';

// import NotificationDetailModal from '@/components/Notification/NotificationDetailModal.vue';

const ionicStore = new Storage();
ionicStore.create();

function isPushAvailable() {
  return Capacitor.isPluginAvailable('PushNotifications');
}

function initPushNotifications(store) {
    if (!isPushAvailable()) {
      return;
    }

    // only try this on iOS and Android apps
    if (isPlatform('capacitor') || isPlatform('hybrid')) {
      /*
       PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
      */
  
      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: Token) => {
          console.debug('Push registration success, FCM Token', token);

          ionicStore.set('deviceId', token.value);
  
          store.dispatch('api/registerDevice', { 
            deviceId: token.value,
            deviceName: Device.model,
            deviceManufacturer: Device.manufacturer,
            deviceOperatingSystem: Device.platform,
            // expiresAt: null
          });
        }
      );
  
      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          alert('Error on registration: ' + JSON.stringify(error));
        }
      );
  
      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotificationSchema) => {
          console.log('Push notification received', notification);
          // alert('Push received: ' + JSON.stringify(notification));

          // const modal = await modalController.create({
          //   component: NotificationDetailModal,
          //   componentProps: {
          //     notification: notification
          //   }
          // });
          // modal.present();
        }
      );
  
      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          console.log('Push action performed', notification);

          store.dispatch('tracking/trackPushNotificationAction', {
              action: 'click',
              path: router.currentRoute.value.path,
              meta: {
                notification: notification
              }
          });

          if (notification.notification && notification.notification.data) {
            const notificationData = notification.notification.data;

            // redirect to the notifications action
            if (notificationData.openInAppNotification && notificationData.openInAppNotification == 'True' && notificationData.inAppNotificationId) {
              store.commit('notification/setPushNotificationAction', notification);
              router.push('/app/notification/' + notificationData.inAppNotificationId);
            } else if (notificationData.pushOpenAppRoute) {
              store.commit('notification/setPushNotificationAction', notification);
              router.push(notificationData.pushOpenAppRoute);
            } else if (notificationData.pushOpenExternalLink) {
              location.href = notificationData.pushOpenExternalLink;
            } else if (notificationData.pushOpenApiCall) {
              // TODO: make api call
            }
          }

          // reset the push notification action after 5 seconds
          setTimeout(() => {
            store.commit('notification/clearPushNotificationAction');
          }, 5000);

          // alert('Push action performed: ' + JSON.stringify(notification));

          // const modal = await modalController.create({
          //   component: NotificationDetailModal,
          //   componentProps: {
          //     notification: notification.notification
          //   }
          // });
          // modal.present();
        }
      );
    }
}

async function getDeviceId() {
  return await ionicStore.get('deviceId');
}

export { isPushAvailable, initPushNotifications, getDeviceId };