<template>
    <ion-app mode="ios">
        <template v-if="environment != 'production'">
            <div :class="{'environment-banner': true, 'environment-banner-beta': environment == 'beta'}" @click="() => $store.commit('performanceStats/togglePerformanceStats')">{{ environment.toUpperCase() }}</div>
        </template>

        <ion-router-outlet />

        <toast-container></toast-container>

        <performance-stats v-if="showPerformanceStats"></performance-stats>
    </ion-app>
</template>

<script lang="ts">
    import { IonApp, IonRouterOutlet, getPlatforms, useKeyboard, createGesture } from '@ionic/vue';
    import { defineComponent, watch } from 'vue';
    import { PushNotifications } from '@capacitor/push-notifications';
    import { isPushAvailable, initPushNotifications, getDeviceId } from './library/push-notifications';
    import { App } from '@capacitor/app';
    import PerformanceStats from './components/Debugging/PerformanceStats.vue';

    import { Storage } from '@ionic/storage';

    import { config } from './config';

    import ToastContainer from './components/Toast/ToastContainer.vue';

    const ionicStore = new Storage();
    ionicStore.create();

    export default defineComponent({
        name: 'App',
        
        components: {
            IonApp,
            IonRouterOutlet,
            ToastContainer,
            PerformanceStats
        },

        computed: {
            environment() {
                return config.ENVIRONMENT;
            },

            showPerformanceStats() {
                return this.$store.state.performanceStats.showPerformanceStats;
            }
        },

        data() {
            return {
                refreshGestureTapLastAt: null,
                refreshGestureTapCount: 0
            }
        },

        setup() {
            const { isOpen, keyboardHeight } = useKeyboard();

            watch(keyboardHeight, () => {
                if (isOpen.value) {
                    document.body.classList.add('keyboard-open');
                } else {
                    document.body.classList.remove('keyboard-open');
                }
            });
        },

        unmonuted() {
            App.removeAllListeners();
        },

        methods: {
            syncPushNotificationPermissions() {
                PushNotifications.checkPermissions().then(async currentPermission => {
                    const hasPermission = currentPermission.receive === 'granted';
                    const deviceId = await getDeviceId();
                    console.debug('Updating device push permissions', {
                        deviceId: deviceId,
                        hasPermission: hasPermission
                    });

                    if (deviceId) {
                        this.$store.dispatch('api/updateDevicePermissions', {
                            deviceId: deviceId,
                            notificationPermission: hasPermission
                        }).catch(err => {
                            console.error('Failed to update device push permissions', err);
                        });
                    }

                }).catch(err => {
                    console.error('Failed to check push notification permissions', err);
                });
            },

            compatibiltyCheck() {
                this.$store.dispatch('compatibility/checkCompatibility').catch(err => {
                    console.error('Failed to check compatibility', err);
                });
            },

            async updateTimezone() {
                try {
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    console.debug('Detected timezone:', timezone);

                    if (!this.$store.state.api.identity || !this.$store.state.api.identity.athleteId) {
                        console.debug('No athlete/identity found - skipping timezone update');
                        return;
                    }

                    const athleteProfile = await this.$store.dispatch('athlete/getProfile', {
                        athlete_id: this.$store.state.api.identity.athleteId
                    });

                    if (athleteProfile && athleteProfile.preferences && athleteProfile.preferences.timeZoneInfoId === 'UTC' && timezone != 'UTC') {
                        console.debug('Updating athletes timezone', timezone);
                        // this.$store.dispatch('athlete/updateProfile', { 
                        //     athlete_id: this.$store.state.api.identity.athleteId, data: { 
                        //         Preferences: { 
                        //             TimeZoneInfoId: timezone 
                        //         } 
                        //     } 
                        // });
                        this.$store.dispatch('athlete/updateTimezone', {data: {TimeZoneId: timezone}}).catch(err => {
                            console.error('Failed to update timezone', err);
                        });
                    }
                } catch (err) {
                    console.error('Failed to update timezone', err);
                }
            },

            initializeReloadGesture() {
                const gesture = createGesture({
                    el: document.body,
                    gestureName: 'quintuple-tap-reload',
                    threshold: 0,
                    onStart: (detail) => {
                        if (this.refreshGestureTapLastAt && Date.now() - this.refreshGestureTapLastAt < 650) {
                            this.refreshGestureTapCount++;
                        } else {
                            this.refreshGestureTapCount = 1;
                        }

                        if (this.refreshGestureTapCount >= 15) {
                            window.location.reload();
                        }

                        this.refreshGestureTapLastAt = Date.now();
                    },
                });

                gesture.enable();
            }
        },

        created() {
            performance.mark('app.startup.start');
        },

        async mounted() {
            // force dark mode
            document.body.classList.add('dark');
            let platforms = getPlatforms();
            for (let i = 0; i < platforms.length; i++) {
                document.body.classList.add('platform-' + platforms[i]);
            }

            // initialize reload gesture
            this.initializeReloadGesture();

            // initialize push notification hooks globally
            initPushNotifications(this.$store);

            App.addListener('appStateChange', state => {
                if (state.isActive) {
                    // redirect to dashboard
                    console.debug('App is active now');
                    const appInactiveTimestamp = localStorage.getItem('app_inactive_timestamp');
                    console.debug('App inactive timestamp', appInactiveTimestamp);
                    if (appInactiveTimestamp) {
                        const inactiveDuration = Date.now() - parseInt(appInactiveTimestamp);
                        console.debug('App inactive duration', inactiveDuration);
                        const diff = 1000 * 60 * 60 * 3; // 3 hours
                        if (inactiveDuration >= diff) {
                            if (!this.$store.state.notification.pushNotificationAction) {
                                console.debug('[APP] No push notification action detected -> redirecting to dashboard');
                                this.$router.replace('/app/dashboard');
                            } else {
                                console.debug('[APP] Push notification action detected -> redirecting to dashboard was skipped');
                            }
                        }
                    }

                    if (this.$store.state.api.identity && this.$store.state.api.identity.athleteId) {
                        this.updateTimezone();
                    }
                } else {
                    // store timestamp when app goes inactive
                    localStorage.setItem('app_inactive_timestamp', Date.now().toString());
                    console.debug('App is inactive now');
                }
            });

            // (re)register device if permission was granted and user is logged in
            const currentUser = await this.$store.dispatch('auth/getCurrentUser');
            if (currentUser) {
                if (isPushAvailable()) {
                    try {
                        const currentPermission = await PushNotifications.checkPermissions();
                        if (currentPermission.receive === 'granted') {
                            PushNotifications.register();
                        }
                    } catch (err) {
                        console.info('Push notifications are not supported on this device', err);
                    }
    
                    App.addListener('appStateChange', state => {
                        if (state.isActive) {
                            this.syncPushNotificationPermissions();
                        }
                    });
    
                    this.syncPushNotificationPermissions();
                }

                this.compatibiltyCheck();

                this.updateTimezone();
            }

            performance.mark('app.startup.end');
        }
    });
</script>